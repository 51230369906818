.charitystack {
    /* justify-content: center; */
    cursor: pointer;
    color: #9a9a9a;
    /* align-items: center; */
    /* margin-top: auto; */
    font-size: 15px;
    padding-right: 4px;
    font-family: "PlantagenetCherokee";
}

.charity {
    /* justify-content: center; */
    cursor: pointer;
    color: #4fb286;
    /* align-items: center; */
    /* margin-top: auto; */
    font-size: 15px;
    /* padding-right: 1px; */
    font-family: "PlantagenetCherokee";
}

.stack {
    /* justify-content: center; */
    cursor: pointer;
    color: #323232;
    /* align-items: center; */
    /* margin-top: auto; */
    font-size: 15px;
    font-family: "PlantagenetCherokee";
}
h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    font-family: "PlantagenetCherokee";
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    font-family: "PlantagenetCherokee";
    margin-left: 0;
    margin-right: 0;
}
h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    font-family: "PlantagenetCherokee";
    margin-right: 0;
}
p {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-family: "PlantagenetCherokee";
}
/* unvisited link */
a:link {
    color: "#34976D";
}

/* mouse over link */
a:hover {
    color: #4ab386;
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
